
















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive<{
      mailAccount: string;
    }>({
      mailAccount: "",
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      dialog: false,
      mailAccounts: [],
    });

    //  Start fetch mail accounts

    const fetchMailAccounts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("/mail-account")
        .then(({ data: { mailAccounts } }) => {
          state.mailAccounts = mailAccounts;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.mailAccounts = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchMailAccounts);

    //  End fetch mail accounts

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        mailAccount: model.mailAccount,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/mail-account`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.mailAccount = event.mailAccount?.id;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.objectExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
    };
  },
});
